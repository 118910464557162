
  import { defineComponent, PropType } from 'vue';

  interface LogoSizes {
    small?: string;
    medium?: string;
    large?: string;
  }

  const sizes: LogoSizes = {
    small: require('../assets/images/logo-checkme.svg'),
    medium: require('../assets/images/logo-checkme.svg'),
    large: require('../assets/images/logo-checkme.svg'),
  };

  export default defineComponent({
    name: 'Logo',
    components: {},

    props: {
      size: {
        type: String as PropType<keyof LogoSizes>,
        required: true,
      },
    },

    setup() {
      return {
        sizes,
      };
    },
  });
