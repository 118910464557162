import {createStore} from 'effector/effector.cjs';
import {createEffect, createEvent} from "effector";
import {makeAuthWithLoginAndPassword} from "@/store/auth";
import moment from "moment";
import appConfig from '@/config';

enum VoxImplantConnectStatus {
    NotConnected,
    Connected
}

const $voxImplantConnectStatus = createStore<VoxImplantConnectStatus>(VoxImplantConnectStatus.NotConnected)
const updateVoxState = createEvent();
const resetVoxState = createEvent();
$voxImplantConnectStatus
    .on(updateVoxState, () => VoxImplantConnectStatus.Connected)
    .reset(resetVoxState)

enum DetailsState {
    NoInit,
    Pending,
    Active,
    Ended,
    Error
}

type ConferenceDetailUnion =
    ConfDetailsUndefined
    | ConfDetailsPending
    | ConfDetailsActive
    | ConfDetailsEnded
    | ConferenceError
type ConfDetailsUndefined = {
    state: DetailsState.NoInit;
}
type ConfDetailsPending = {
    state: DetailsState.Pending;
    details: ConferenceDetails;
    leftTime: LeftTime
}
type ConfDetailsActive = {
    state: DetailsState.Active;
    details: ConferenceDetails;
}
type ConfDetailsEnded = {
    state: DetailsState.Ended;
    details: ConferenceDetails;
}

enum ErrorCode {
    NotFound
}

type ConferenceError = {
    state: DetailsState.Error
    code: ErrorCode
}

type LeftTime = {
    totalSeconds: number;
    hours: number;
    minutes: number;
    seconds: number
}
const SECONDS_IN_HOUR = 60 * 60;
const SECONDS_IN_MINUTE = 60;
const leftTimeCalculator = (start: Date): LeftTime => {
    const totalSeconds = moment(start).diff(moment(), 'seconds');
    const hours = Math.floor(totalSeconds / SECONDS_IN_HOUR)
    const leftSecondsForMin = totalSeconds % SECONDS_IN_HOUR
    const mins = Math.floor(leftSecondsForMin / SECONDS_IN_MINUTE)
    const leftSecondsForSeconds = leftSecondsForMin % SECONDS_IN_MINUTE
    //console.log('totalSeconds ' + totalSeconds)
    return {
        totalSeconds: totalSeconds,
        hours: hours,
        minutes: mins,
        seconds: leftSecondsForSeconds
    }
};
const calculateState = (details: ConferenceDetails): ConferenceDetailUnion => {
    const leftToStartTime = leftTimeCalculator(details.startAt)
    if (leftToStartTime.totalSeconds > 0) {
        setTimeout(() => {
            pendingTick()
        }, 1000)
        return {
            state: DetailsState.Pending,
            details: details,
            leftTime: leftToStartTime
        }
    } else {
        const leftToEndTime = leftTimeCalculator(details.endAt)
        if (leftToEndTime.totalSeconds > 0) {
            return {
                state: DetailsState.Active,
                details: details
            }
        } else {
            return {
                state: DetailsState.Ended,
                details: details
            }
        }
    }
    throw "State not defined"
}

interface ConferenceDetails {
    conferenceId: string;
    description: string;
    startAt: Date;
    endAt: Date;
}

const $conferenceStore = createStore<ConferenceDetailUnion>({state: DetailsState.NoInit});
const setConferenceDetails = createEvent<ConferenceDetails>()
const setError = createEvent<ErrorCode>()
const pendingTick = createEvent()
$conferenceStore
    .on(pendingTick, (state) => {
        if (state.state == DetailsState.NoInit || state.state == DetailsState.Error) return state
        return calculateState(state.details)
    })
    .on(setConferenceDetails, (s, value) => {
        return calculateState(value)
    })
    .on(setError, (s, v)=>{
        return {
            state: DetailsState.Error,
            code: v
        }
    })

export type Success<T> = { success: true; body: T };
export type Error<E> = { success: false; body: E };
export type Result<T, E> = Success<T> | Error<E>;
export const Result = Object.freeze({
    ok: <T, E>(body: T): Result<T, E> => ({success: true, body: body}),
    error: <T, E>(body: E): Result<T, E> => ({success: false,body: body}),
});

const getDetalization = async (conferenceId: string): Promise<Result<ConferenceDetails, ErrorCode>> => {
    const response = await fetch(`${appConfig.baseServerUrl}public/api/conference/detail`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({code: conferenceId}),
    });
    if (response.ok) {
        const data = await response.json()
        return Result.ok({
            conferenceId: conferenceId,
            description: data.description,
            startAt: moment.utc(data.startAt).toDate(),
            endAt: moment.utc(data.endAt).toDate()
        })
    } else {
        return Result.error(ErrorCode.NotFound)
    }
};

const connectEffect = createEffect<string, void, void>(async (conferenceId) => {
    await makeAuthWithLoginAndPassword({login: 'user-1', password: 'user-1'});
    updateVoxState();

    const details = await getDetalization(conferenceId)
    if (details.success){
        setConferenceDetails(details.body)
    }else{
        setError(details.body)
    }
});

export {
    updateVoxState,
    DetailsState,
    VoxImplantConnectStatus,
    setConferenceDetails,
    $conferenceStore,
    connectEffect,
    ErrorCode
};
